"use strict";

import { createRouter, createWebHistory } from "vue-router";

const routes = [];

routes.push({
    "path": "",
    "redirect": {
        "name": "analysisDashboard",
    },
});

routes.push({
    "path": "",
    "component": () => import("./layouts/AuthenticationFrame.vue"),
    "children": [
        {
            "path": "login",
            "children": [
                {
                    "path": "",
                    "name": "login",
                    "component": () => import("./views/LogIn"),
                    "props": () => ({
                        "name": "login",
                    }),
                    "meta": {
                        "requiresAuth": false,
                    },
                },
                {
                    "path": "exception",
                    "name": "loginException",
                    "component": () => import("./views/LoginException"),
                    "props": () => ({
                        "name": "loginException",
                    }),
                    "meta": {
                        "requiresAuth": false,
                    },
                },
            ],
        },
        {
            "path": "api",
            "name": "api",
            "component": () => import("./views/ApiTest"),
            "props": () => ({
                "name": "api",
            }),
            "meta": {
                "requiresAuth": false,
            },
        },
    ],
});

routes.push({
    "path": "",
    "component": () => import("./layouts/DashboardFrame.vue"),
    "children": [
        {
            "path": "account/create",
            "children": [
                {
                    "path": "",
                    "name": "accountCreateInput",
                    "component": () => import("./views/AccountCreateInput"),
                    "props": () => ({
                        "name": "accountCreateInput",
                    }),
                    "meta": {
                        "menu": "accountCreate",
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "confirm",
                    "name": "accountCreateConfirm",
                    "component": () => import("./views/AccountCreateConfirm"),
                    "props": () => ({
                        "name": "accountCreateConfirm",
                    }),
                    "meta": {
                        "menu": "accountCreate",
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "category",
                    "name": "accountCreateCategory",
                    "component": () => import("./views/AccountCreateCategory"),
                    "props": () => ({
                        "name": "accountCreateCategory",
                    }),
                    "meta": {
                        "menu": "accountCreate",
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "plan",
                    "name": "accountCreatePlan",
                    "component": () => import("./views/AccountCreatePlan"),
                    "props": () => ({
                        "name": "accountCreatePlan",
                    }),
                    "meta": {
                        "menu": "accountCreate",
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "bankTransfer",
                    "children": [
                        {
                            "path": "",
                            "name": "accountCreateBankTransferInput",
                            "component": () => import("./views/AccountCreateBankTransferInput"),
                            "props": () => ({
                                "name": "accountCreateBankTransferInput",
                            }),
                            "meta": {
                                "menu": "accountCreate",
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "confirm",
                            "name": "accountCreateBankTransferConfirm",
                            "component": () => import("./views/AccountCreateBankTransferConfirm"),
                            "props": () => ({
                                "name": "accountCreateBankTransferConfirm",
                            }),
                            "meta": {
                                "menu": "accountCreate",
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "complete",
                            "name": "accountCreateBankTransferComplete",
                            "component": () => import("./views/AccountCreateBankTransferComplete"),
                            "props": () => ({
                                "name": "accountCreateBankTransferComplete",
                            }),
                            "meta": {
                                "menu": "accountCreate",
                                "requiresAuth": true,
                            },
                        },
                    ],
                },
                {
                    "path": "complete",
                    "name": "accountCreateComplete",
                    "component": () => import("./views/AccountCreateComplete"),
                    "props": () => ({
                        "name": "accountCreateComplete",
                    }),
                    "meta": {
                        "menu": "accountCreate",
                        "requiresAuth": true,
                    },
                },
            ],
        },
        {
            "path": "",
            "children": [
                {
                    "path": "",
                    "name": "analysisDashboard",
                    "component": () => import("./views/AnalysisDashboard"),
                    "props": () => ({
                        "name": "analysisDashboard",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "feed",
                    "name": "analysisFeed",
                    "component": () => import("./views/AnalysisFeed"),
                    "props": () => ({
                        "name": "analysisFeed",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "reel",
                    "name": "analysisReel",
                    "component": () => import("./views/AnalysisReel"),
                    "props": () => ({
                        "name": "analysisReel",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "stories",
                    "name": "analysisStories",
                    "component": () => import("./views/AnalysisStories"),
                    "props": () => ({
                        "name": "analysisStories",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
            ],
        },
        {
            "path": "hashtag/text",
            "name": "analysisHashtag",
            "component": () => import("./views/AnalysisHashtag"),
            "props": () => ({
                "name": "analysisHashtag",
            }),
            "meta": {
                "requiresAuth": true,
            },
        },
        {
            "path": "hashtag/image",
            "name": "analysisHashtagImage",
            "component": () => import("./views/AnalysisHashtagImage"),
            "props": () => ({
                "name": "analysisHashtagImage",
            }),
            "meta": {
                "requiresAuth": true,
            },
        },
        {
            "path": "conflict",
            "children": [
                {
                    "path": "",
                    "name": "analysisConflict",
                    "component": () => import("./views/AnalysisConflict"),
                    "props": () => ({
                        "name": "analysisConflict",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "detail",
                    "name": "analysisConflictDetail",
                    "component": () => import("./views/AnalysisConflictDetail"),
                    "props": () => ({
                        "name": "analysisConflictDetail",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
            ],
        },
        {
            "path": "account",
            "children": [
                {
                    "path": "",
                    "name": "accountDetail",
                    "component": () => import("./views/AccountDetail"),
                    "props": () => ({
                        "name": "accountDetail",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "update",
                    "children": [
                        {
                            "path": "",
                            "name": "accountUpdateInput",
                            "component": () => import("./views/AccountUpdateInput"),
                            "props": () => ({
                                "name": "accountUpdateInput",
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                    ],
                },
                {
                    "path": "category",
                    "name": "accountCategory",
                    "component": () => import("./views/AccountCreateCategory"),
                    "props": () => ({
                        "name": "accountCategory",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
            ],
        },
        {
            "path": "plan",
            "children": [
                {
                    "path": "",
                    "name": "accountDetailPlan",
                    "component": () => import("./views/AccountDetailPlan"),
                    "props": () => ({
                        "name": "accountDetailPlan",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "update",
                    "name": "accountUpdatePlan",
                    "component": () => import("./views/AccountCreatePlan"),
                    "props": () => ({
                        "name": "accountUpdatePlan",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "bankTransfer",
                    "children": [
                        {
                            "path": "",
                            "name": "accountBankTransferInput",
                            "component": () => import("./views/AccountCreateBankTransferInput"),
                            "props": () => ({
                                "name": "accountBankTransferInput",
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "confirm",
                            "name": "accountBankTransferConfirm",
                            "component": () => import("./views/AccountCreateBankTransferConfirm"),
                            "props": () => ({
                                "name": "accountBankTransferConfirm",
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "complete",
                            "name": "accountBankTransferComplete",
                            "component": () => import("./views/AccountCreateBankTransferComplete"),
                            "props": () => ({
                                "name": "accountBankTransferComplete",
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                    ],
                },
                {
                    "path": "cancel",
                    "children": [
                        {
                            "path": "",
                            "name": "accountDelete",
                            "component": () => import("./views/AccountDelete"),
                            "props": () => ({
                                "name": "accountDelete",
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                        {
                            "path": "complete",
                            "name": "accountDeleteComplete",
                            "component": () => import("./views/AccountDeleteComplete"),
                            "props": () => ({
                                "name": "accountDeleteComplete",
                            }),
                            "meta": {
                                "requiresAuth": true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            "path": "hashtag/base",
            "name": "accountHashtag",
            "component": () => import("./views/AccountHashtag"),
            "props": () => ({
                "name": "accountHashtag",
            }),
            "meta": {
                "requiresAuth": true,
            },
        },
        {
            "path": "contact",
            "children": [
                {
                    "path": "",
                    "name": "contactInput",
                    "component": () => import("./views/ContactInput"),
                    "props": () => ({
                        "name": "contactInput",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "confirm",
                    "name": "contactConfirm",
                    "component": () => import("./views/ContactConfirm"),
                    "props": () => ({
                        "name": "contactConfirm",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
                {
                    "path": "complete",
                    "name": "contactComplete",
                    "component": () => import("./views/ContactComplete"),
                    "props": () => ({
                        "name": "contactComplete",
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                },
            ],
        },
    ],
});

// 存在しないページはrootへリダイレクト
routes.push({
    "path": "/:pathMatch(.*)",
    "redirect": {
        "name": "analysisDashboard",
    },
});

// new router
const router = createRouter({
    "history": createWebHistory("/"),
    "routes": routes,
    scrollBehavior(to) {
        if (!to.hash) {
            const content = document.getElementById("content");
            if (content) {
                document.getElementById("content").scrollTop = 0;
            }
            // return {
            //     "top": 0,
            //     "left": 0,
            // };
        }
    },
});

// auth check
// eslint-disable-next-line no-unused-vars
// router.beforeEach(async (to, from) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     if (requiresAuth) {
//         if (localStorage.getItem("token") === null) {
//             return { "name": "login" };
//         }
//     }
// });

export default router;
